import React,{createContext, useState, useEffect } from 'react';

export const DataContext = createContext();

export const DataProvider = (props) => {
    const [courses, setCourses] = useState([
        {
            '_id':'1',
            'image': '../../images/img-2.jpg',
            'heading': 'Courses in Social inclusion and Gender',
            'note': 'Improved access to livelihood access and services for all',
            'link': '/SocialInclusionandGender',
            'price': 200,
            'time': '3 weeks',
            'headline': 'This course is designed for leaders and practitioners in both the public and private sector.',
            'furtherdesc':
              'The course provides participants with key methodologies, approaches and effective strategies to practical social inclusion dimensions',
            'how': 'Online with two scheduled virtual sessions',
            'count': 1
          },
          {
            '_id':'2',
            'image': '../../images/img-3.jpg',
            'heading': 'Grant Development, writing and Management',
            'note': 'Key aspects of developmment, writing and management',
            'link': '/GrantDevelopment',
            'price': 400,
            'time': '4 weeks',
            'headline': 'Grant Managers, Project Implementation Teams, Supervisors',
            'furtherdesc':
              'From the Idea to results, the course covers the process and methods an organization uses to harness its grants.',
            'how': 'Online with two scheduled virtual sessions',
            'count': 1
          },
          {
            "_id":'3',
            'image': '../../images/img-4.jpg',
            'heading': 'Financial Management',
            'note': 'Planning, organizing, controlling and monitoring financial resources.',
            'link': '/GrantDevelopment',
            'price': 200,
            'time': '2 weeks',
            'headline': 'Finance, Administration staff, Project Managers',
            'furtherdesc':
              'The course includes trending financial management practices, compliance and account management practices.',
            'how':'Online with two scheduled virtual sessions',
            'count': 1
          },
            
          {
            '_id':'4',
            'image': '../../images/img-5.jpg',
            'heading': 'Leadership Etiquete and Traction',
            'note': 'Qualities of a great leader and the descriptions apply to aspects of etiquette ',
            'link': '/LeadershipEtiquette',
            'price': 200,
            'time': '2 weeks',
            'headline': 'Middle and Senior level managers in both public and private institutions, CSOs and individual entrepreneurs',
            'furtherdesc':
              'The course provides participants with 21 st century cutting edge information to blossom and attain results. Founded in a mix of leadership styles, the course is a preliquisite for leading for change and transformation.',
            'how': 'Online with two scheduled virtual sessions',
            'count': 1
          },
          {
            '_id':'5',
            'image': '../../images/img-6.jpg',
            'heading': 'Developing Impactful Youth Programs',
            'note': 'Several designs of the parameters of the program that engage the youth',
            'link': '/ImpactfulYouthPrograms',
            'price': 500,
            'time': '4 weeks',
            'headline': 'Development workers, Project Managers and Teams, Social Workers, Students who hope to have a career in Youth work',
            'furtherdesc':
              'The course covers major principles in youth programming, the participation pyramid, effective mobilization and Engagement',
            'how': 'Online with two scheduled virtual sessions',
            'count': 1
          },
          {
            '_id':'6',
            'image': '../../images/img-7.jpg',
            'heading': 'Dealing with attitudes and mindsets',
            'note': 'Ideas and attitudes that shapes ones way of thinking',
            'link': '/DealingwithAttitudeandMindests',
            'headline': 'Everybody, will be managed by categories at admission',
            'furtherdesc':
              'All you need to know about mindset change',
            'time': 'Minimum 4 weeks, varies on needs varying and the fee varies',
            'how': 'Online with multiple scheduled virtual and physical sessions',
            'count': 1
          },
          {
            '_id':'7',
            'image': '../../images/img-8.jpg',
            'heading': 'Mental health',
            'note': 'Realities of living with mental health and improve your wellbeing',
            'link': '/MentalHealth',
            'time': 'Continuous based on need and the fee varies',
            'price': 'Varying',
            'headline': 'Everybody, will be managed by categories at admission',
            'furtherdesc':
              'All you need to know about mental health',
            'how': 'Online with scheduled virtual and physical sessions',
            'count': 1
           },
        
    ])

    const [cart, setCart] = useState([])

    const addCourse = (id) => {
      const check = cart.every(item =>{
        return item._id !== id
      })
      if(check){
        const data = courses.filter(course =>{
          return course._id == id 
        })
        setCart([...cart, ...data])
      }
    }

    useEffect(() =>{
      localStorage.setItem('dataCart', JSON.stringify(cart))
    },[cart])

    const  value = {
      courses: [courses, setCourses],
      cart: [cart, setCart],
      addCourse: addCourse
    }
    return (
          
        <DataContext.Provider value={value}>
          {props.children}
        </DataContext.Provider>
    )
}


