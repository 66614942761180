import React from 'react'
import { course7 } from './Data';
import { CoursesSection } from '../../Components';

function MentalHealth() {
    return (
        <>
          <CoursesSection {...course7} />
        </>
      );
}

export default MentalHealth
