import React from 'react';
import { course1 } from './Data';
import {CoursesSection } from '../../Components'

function SocialInclusionandGender() {
  return (
    <>
    <CoursesSection {...course1} />
  </>
);
  
}

export default SocialInclusionandGender;

