import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";

import Services from "./Components/Services/Services"
import Checkout from "./Components/CheckOut/Checkout";
import SignIn from "./Components/login/signinForm";
import SignUpForm from "./Components/SignUP/SignUpForm";
import Reset from "./Components/PasswordReset/Reset";
import SocialInclusionandGender from "./pages/CoursePage/SocialInclusionandGender";
import DealingwithAttitudesandMindsets from "./pages/CoursePage/DealingwithAttitudesandMindsets";
import FinancialManagement from "./pages/CoursePage/FinancialManagement";
import GrantDevelopment from "./pages/CoursePage/GrantDevelopment";
import MentalHealth from "./pages/CoursePage/MentalHealth";
import LeadershipEtiquette from "./pages/CoursePage/LeadershipEtiquette";
import ImpactfulYouthPrograms from "./pages/CoursePage/ImpactfulYouthPrograms";
import Appbar from "./Components/Appbar/appbarLanding";
import CardTest from "./Components/Cards/CardsTrial";
import { About } from "./Components/About/About";
import PricingContent from './Components/EmailVerification/EmailSent';
import Album from "./Components/Album/album";
import AnswerYo from './Components/AnswerYo/answeryo';
import Courselist from "./Components/Cards/Courselist";
import Details from "./Components/Cards/Details";
import Cart from "./Components/Cards/Cart"





function App() {
  return (
    
  
      <Router>
        <Switch>
          <Route path ='/'  component={LandingPage} exact />
          <Route path ='/Dashboard'  component={Dashboard} />
          <Route path ='/Services'  component={Services} />
          <Route path ='/SignUp'  component={SignUpForm} />
          <Route path = '/AnswerYo' component={AnswerYo}/>
          <Route path ='/Checkout'  component={Checkout} />
          <Route path ='/Signin'  component={SignIn} />
          <Route path = '/Reset'  component ={Reset} />
          <Route  path ='/SocialInclusionandGender'  component={SocialInclusionandGender} />
          <Route path = '/FinancialManagement' component={FinancialManagement}/>
          <Route path ='/DealingwithAttitudeandMindests' component={DealingwithAttitudesandMindsets} />
          <Route path = '/GrantDevelopment' component= {GrantDevelopment} />
          <Route path = '/MentalHealth' component= {MentalHealth} />
          <Route path = '/LeadershipEtiquette' component= {LeadershipEtiquette} />
          <Route path = '/ImpactfulYouthPrograms' component= {ImpactfulYouthPrograms} />
          <Route path = '/checkout' component={Checkout} />
          <Route path='/appbar'  component={Appbar} />
          <Route path='/cardtest'  component={CardTest} />
          
          <Route path ='/About' component={About}  />
          <Route path ='/Price' component={PricingContent}  />
          <Route path ='/album' component={Album}  />
          

          <section>
            <Switch>
              <Route path='/Courses' component = {Courselist } exact />
              <Route path='/Courses/:id' component = {Details}   />
              <Route path='/Cart' component = {Cart}   />
            </Switch>
          </section> 
          
         
        </Switch>
    </Router>
    
  )
}


export default App;
