import React,{useContext, useState, useEffect} from 'react';
import { DataContext } from "../Cards/DataProvider";
import '../Cards/Details.css'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
submit: {
  margin: theme.spacing(3, 0, 2),
  backgroundColor: 'red',
  color: 'white'
},
}));

export default function PaymentForm() {
  const classes = useStyles();
  const value = useContext(DataContext);
     const [cart, setCart] = value.cart
    const [total,setTotal] = useState(0)
   

    useEffect(() => {
      const getTotal = () => {
        const res = cart.reduce((prev, item) => {
          return prev+ (item.price * item.count)
        },0)
        setTotal(res)
      }
      getTotal()
    },[cart])

    const reduction = id =>{
        cart.forEach(item =>{
            if(item._id === id){
                item.count === 1 ? item.count = 1 : item.count -= 1;
            }
            
        })
        setCart([...cart])
    }
    const increment = id =>{
        cart.forEach(item =>{
            if(item._id === id){
                item.count += 1 ;
            }
            
        })
        setCart([...cart])
    }
  return (
    <React.Fragment>
      <div>
            {cart.map(course => (
                <div>
                <div className="details" key={course._id}>
                    <h6>Payments made for {course.heading} </h6>
                    <div className="box-details">
                      <div className="amount">
                        <Typography variant="h6" gutterBottom>Indicate the number of people that are being paid for</Typography>
                        <button className="count" onClick={() => reduction(course._id)}> - </button>
                        <span>{course.count}</span>
                        <button className="count" onClick={() => increment(course._id)}> + </button>
                      </div>
                       
                    </div>
                </div>
               

                </div>
             
            )
            
            )}
            <div className="total">
                <h5>Total amount to be paid: $ {total}</h5>
            </div>        

        </div>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Button variant='contained'>Credit Card</Button>
      <Grid container spacing={3}>
      
      
        <Grid item xs={12} md={6}>
          <TextField required id="cardName" label="Name on card" fullWidth autoComplete="cc-name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField required id="expDate" label="Expiry date" fullWidth autoComplete="cc-exp" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid>
        
      <Link style={{textDecoration: 'none'}} to={{pathname:'https://www.paypal.com'}} target="_blank">
          <Button variant='contained' color="primary" className={classes.submit}  >
          PAYPAL
          </Button></Link>

      </Grid>
    </React.Fragment>
  );
}