import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';
import logo from "../../images/globalaltlogo3.png"

import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks
} from './NavBarEl';

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  
  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  
  const handleLogout = (history) => {
    history.push('/');
  }
  
  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
        <Nav>
          <NavbarContainer>
            <NavLogo style={{
           textDecoration: 'none'
          }} >
            <img src={logo} alt=""  width="170"/>
              <span>Global Alternatives Online Platform</span>
              
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
            <NavItem>
                <NavLinks style={{textDecoration: 'none'}}  to='/About' onClick={closeMobileMenu}>
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks style={{textDecoration: 'none'}}  to='/Courses' onClick={closeMobileMenu}>
                  Courses
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks style={{textDecoration: 'none'}}  to='/AnswerYo' onClick={closeMobileMenu}>
                  AnswerYoApp
                </NavLinks>
              </NavItem>
              <NavItem style={{textDecoration: 'none'}} >
                <NavLinks style={{textDecoration: 'none'}} to='/Services' onClick={closeMobileMenu}>
                  Consultancy Services 
                </NavLinks>
              </NavItem>
              <NavItemBtn>
                {button ? (
                  
                    <Button style={{textDecoration: 'none'}} onLogout={handleLogout}  primaryprice  >LOGOUT</Button>
                  
                ) : (
                  
                    <Button style={{textDecoration: 'none'}} fontBig primary >
                      LOGOUT
                    </Button>
                  
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default (Navbar);