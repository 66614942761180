import React,{useContext, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import {DataContext} from './DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import './Details.css'
import '../Cards/Details.css'



const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(12, 0, 4),
      justifyContent: 'left',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      
  
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
    },
    cardMedia: {
      paddingTop: '66.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

function Cart() {
    const classes = useStyles();
    const value = useContext(DataContext);
     const [cart, setCart] = value.cart
    const [total,setTotal] = useState(0)
   

    useEffect(() => {
      const getTotal = () => {
        const res = cart.reduce((prev, item) => {
          return prev+ (item.price * item.count)
        },0)
        setTotal(res)
      }
      getTotal()
    },[cart])

    const reduction = id =>{
        cart.forEach(item =>{
            if(item._id === id){
                item.count === 1 ? item.count = 1 : item.count -= 1;
            }
            
        })
        setCart([...cart])
    }
    const increment = id =>{
        cart.forEach(item =>{
            if(item._id === id){
                item.count += 1 ;
            }
            
        })
        setCart([...cart])
    }




    return (
        <div>
            {cart.map(course => (
                <div>
                <div className="details" key={course._id}>
                    <div className="img-container" style={{backgroundImage: `url(${course.image})`}}></div>
                    <div className="box-details">
                      <h2 title={course.heading}>{course.heading}</h2>
                      <h5>Who(Target) is the course aiming at</h5>
                      <p> {course.headline}</p>

                      
                      <h5>Aim of the course </h5>
                      <p>{course.furtherdesc}{course.note}</p>
                      
                      <h5>Fee charged to access the course </h5>
                      <p> ${course.price}</p>
                      <h5>Duration of the course </h5>
                      <p>{course.time}</p>
                      <h5>How the course will be conducted</h5>
                      <p>{course.how}</p>


                      <div className="amount">
                        <h6>Number of personels that are being paid for</h6>
                        <button className="count" onClick={() => reduction(course._id)}> - </button>
                        <span>{course.count}</span>
                        <button className="count" onClick={() => increment(course._id)}> + </button>
                      </div>
                       
                    </div>
                </div>
               

                </div>
             
            )
            
            )}
            <div className="total">
                <Link to="/Cart">PAYMENT</Link>
                <h3>Total: $ {total}</h3>
            </div>        

        </div>
    )
}

export default Cart
  