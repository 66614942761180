import React from 'react'
import { course6 } from './Data';
import { CoursesSection } from '../../Components';

function DealingwithAttitudesandMindsets() {
    return (
        <>
          <CoursesSection {...course6} />
        </>
      );
}

export default DealingwithAttitudesandMindsets
