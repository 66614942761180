import React, {useContext, useState, Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Courselist from './Courselist';




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(12, 0, 4),
    justifyContent: 'left',
  },
  heroButtons: {
    marginTop: theme.spacing(1),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    

  },
  maintext: {
    marginTop: '50px',
    marginLeft: '450px',
    fontSize:'40px',
    padding: '0, 15px',
    marginBottom: '15px',
    fontWeight: '100',
    color:'red'
  },
  subtext: {
    marginTop: '30px',
    fontSize: '22px',
    fontWeight: '500',
    marginLeft: '120px',
    
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  cardMedia: {
    paddingTop: '66.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  button: {
    background: 'red',
    borderRadius: 3,
    border: 0,
    color: 'blue',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
});


export class CourseTest extends Component {
  
 

  
  
  
  render(){
    const { classes} = this.props;
  return (
    
    <React.Fragment>
      
      <CssBaseline />
      <div className={classes.maintext}>
            Welcome to the Global Alternatives Online Platform.
          </div>
          <div className={classes.subtext}>An online center for amazing services that include Online Courses, Consultancy Services and AnswerYo app. Our aim is to make a contribution to your knowledge base and be sure that you will gain a lot.</div>

        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="left" color="textPrimary" paragraph>
              Available courses
            </Typography>
          </Container>
        </div>
        <Courselist />
              
          
        
    </React.Fragment>
  );
}
}
export default (withStyles(useStyles)(CourseTest))