import React, { useContext } from 'react'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from 'react-router-dom'
import formatCurrency from 'format-currency';
import  CartContext  from '../../context/cart/CartContext';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from './DataProvider';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(12, 0, 4),
    justifyContent: 'left',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    

  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  cardMedia: {
    paddingTop: '66.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const Courselist = () => {
  const classes = useStyles();

  const value = useContext(DataContext);
  const [courses] = value.courses 
  
 
    return (
      <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4} >
            
            {courses.map((course) => (
              <Grid item  xs={12} sm={6} md={4}>
                <Card className={classes.card} key={course._id}>
               
            <CardMedia
              className={classes.cardMedia}
              image={course.image}                   
            />
            <CardContent className={classes.cardContent} >
              <Typography 
                  gutterBottom 
                  variant="h6" 
                  component="h3"
                  >
                  {course.heading}
              </Typography>
              <Typography variant="inherit">
              {course.note}
              </Typography>
              <Typography variant="h6" color="textPrimary">
              ${course.price} 
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {course.time}
              </Typography>
            
            </CardContent>
            <CardActions>
              <Link to={ `/Courses/${course._id}`} >
                <Button variant="outlined" size="large" style={{ backgroundColor: 'red', color: 'white'}}>
                  Start course
                </Button>
              </Link>
              
            </CardActions>
          </Card>
              </Grid>))}
              </Grid>
              
              </Container>
    )
}

export default Courselist
