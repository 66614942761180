import React from 'react'
import { course4 } from './Data';
import { CoursesSection } from '../../Components';

function LeadershipEtiquette() {
    return (
        <>
          <CoursesSection {...course4} />
        </>
      );
}

export default LeadershipEtiquette
