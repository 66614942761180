import React from 'react'
import { course2 } from './Data';
import { CoursesSection, Pricing } from '../../Components';

function GrantDevelopment() {
    return (
        <>
          <CoursesSection {...course2} />
        </>
      );
}

export default GrantDevelopment
