import React,{useContext, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import {DataContext} from './DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { Button } from '../../globalStyles';
import './Details.css';
import Navbar from '../Navbar/NavBar1';



const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(12, 0, 4),
      justifyContent: 'left',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      
  
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
    },
    cardMedia: {
      paddingTop: '66.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

function Details() {
    const classes = useStyles();

    const {id} = useParams();
    const value = useContext(DataContext);
    const[courses] = value.courses;
    const [total,setTotal] = useState(0)
    const [cart] = value.cart
    const addCourse = value.addCourse

    // useEffect(() => {
    //   const getTotal = () => {
    //     const res = courses.reduce((item) => {
    //       return (item.price * item.count)
    //     },0)
    //     setTotal(res)
    //   }
    //   getTotal()
    // },[courses])


    const details = courses.filter((course, index) =>{
        return course._id === id
    })


    return (
        <div>
          <Navbar />
          
          <h2>Course Summary</h2>
            {details.map(course => (
                <div>
                <div className="details" key={course._id}>
                    <div className="img-container" style={{backgroundImage: `url(${course.image})`}}></div>
                    <div className="box-details">
                      <h2 title={course.heading}>{course.heading}</h2>
                      <h5>Who(Target) is the course aiming at</h5>
                      <p> {course.headline}</p>

                      
                      <h5>Aim of the course </h5>
                      <p>{course.furtherdesc}{course.note}</p>
                      
                      <h5>Fee charged to access the course </h5>
                      <p> ${course.price}</p>
                      <h5>Duration of the course </h5>
                      <p>{course.time}</p>
                      <h5>How the course will be conducted</h5>
                      <p>{course.how}</p>

                       <div >
                        <Link to="/Checkout"><Button  onClick={() => addCourse(course._id)}>PROCEED TO PAY</Button></Link>
                        
                      </div>  
                    </div>
                </div>
               

                </div>
             
            )
            
            )}
          

        </div>
    )
}

export default Details
  