
import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./Footerstyles";
import logo from "../../images/globalaltlogo3.png"

const Footer = () => {
return (
	<Box>
	<Container>
		<Row>
		<Column>
			<Heading>About Us</Heading>
			<FooterLink href="/About">Aim</FooterLink>
			<FooterLink href="/About">Vision</FooterLink>
			
		</Column>
		<Column>
			<Heading>Social Media</Heading>
			<FooterLink href="https://www.facebook.com/globalalternatives">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://twitter.com/gloa16?lang=en">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="www.globalalternativesug.org">
			<i >
				<span style={{ marginLeft: "10px" }}>
				Our organisational website
				</span>
			</i>
			</FooterLink>
		</Column>
		<Column>
		<img src={logo} alt='logo'
                style={{width:"170"}} /> 
			<span style={{color:"black",paddingTop:"40px"}} >&copy;{new Date().getFullYear()} Global Alternatives Learning Platform</span>
			<span>P.O Box 6530, Kampala</span>
			<span>+256 701 180 000 </span>
		</Column>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;
