import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Link from '@material-ui/core/Link';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../globalStyles';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxheight: '80vh',
    // justifyContent: 'center',
},
  
  image: {
    backgroundImage: 'url(../../images/Online.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  maintext: {
    marginTop: '10px',
    fontSize:'50px',
    padding: '0, 15px',
    marginBottom: '15px',
    fontWeight: '700'
  },
  subtext: {
    marginTop: '30px',
    fontSize: '22px',
    fontWeight: '500',
    marginBottom: '130px'
  },
  
}));


export default function SignInSide() {
  const classes = useStyles();

  return (
    <Grid container  className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.maintext}>
            Welcome to the <div style={{color: 'red'}}>Global Alternatives Online Platform.</div>
          </div>
          <div className={classes.subtext}>An interactive platform that supports learning
         at convenience.Our aim is to make a contribution to your knowledge base
          and be sure that you will gain a lot.This is achived from our services that are provided</div>
          <Link style={{textDecoration: 'none'}} to='/SignUp'><Button >
            GET STARTED
          </Button></Link>
        </div>
      </Grid>
      <Grid item xs={false} sm={12} md={6} className={classes.image} />
    </Grid>
   
  );
}