
import React, { Component, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextValidator , ValidatorForm} from 'react-material-ui-form-validator';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import emailjs from 'emailjs-com';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" >
        Global Alternatives Online Platform
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'red',
    color: 'white'
  },
  
});


class Services extends Component{

  state={
    names: '',
    institution: '',
    email: '',
    tel: '',
    service: ''
  }

    
 handleChange= e => {
   this.setState({
    [e.target.name]: e.target.value
  })
   };

 handleSubmit = (e) => {

  e.preventDefault();

  emailjs.sendForm('service_6r3oh7c', 'template_7jz45ug', e.target, 'user_rk1DWQcncRjJxrYNEgejk' )
    .then((result) => {
      console.log(result.text);
      alert("Message successfully sent, We shall get back back to you shortly", result.text);

    },
    (error) => {
      alert("An error occured, Please try again", error.text);
    }
    );
    e.target.reset();
  }


  render(){
    const {classes} = this.props;
    return (
      <Container component="main" maxWidth="lg" >
        <CssBaseline />
        <div className={classes.paper} style={{align:'center'}} >
        <Typography variant="h5" align="center" color="textSecondary" component="p" >
        The Consultancy and Professional service
         </Typography>
        
          <Typography component="h1" variant="h5">
          Offered in different fields of study, we have a pool of qualified and competent professionals to respond to your needs as a client.
          Using participatory methodologies and modern virtual approaches, Consider it done with our team of experts.
          </Typography>
          <Typography component="h1" variant="h5">
            Fill in the following information to learn more about our services
          </Typography>
          
          <ValidatorForm
            className={classes.form} 
            onSubmit={this.handleSubmit}
            onError={errors => {
              for (const err of errors){
                console.log(err.props.errorMessages[0])
              }
            }}
            >
            <TextValidator
              variant="outlined"
              margin="normal"
              fullWidth
              id="names"
              label="Names"
              name="names"
              value={this.state.names}
              onChange={this.handleChange} 
              autoComplete="off"
              errorMessages={['This field is required']}
              validators={['required']}
              autoFocus
            />
            <TextValidator
              variant="outlined"
              margin="normal"
              fullWidth
              id="institution"
              label="Institution/Company"
              name="institution"
              value={this.state.institution}
              onChange={this.handleChange} 
              autoComplete="off"
              errorMessages={['This field is required']}
              validators={['required']}
              
            />
            <TextValidator
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={this.state.email}
              onChange={this.handleChange} 
              autoComplete="off"
              errorMessages={['This field is required', 'Email is not valid']}
              validators={['required', 'isEmail']}
              
            />
            <TextValidator
              variant="outlined"
              margin="normal"
              fullWidth
              name="tel"
              label="Telephone Number"
              type="tel"
              id="tel"
              validators={['required']}
              errorMessages={['This field is required']}
              value={this.state.tel}
              onChange={this.handleChange} 
              autoComplete="off"
            />
            <TextValidator
              variant="outlined"
              margin="normal"
              fullWidth
              name="service"
              label="Describe the Services you need"
              type="service"
              id="service"
              validators={['required']}
              errorMessages={['This field is required']}
              value={this.state.service}
              onChange={this.handleChange} 
              autoComplete="off"
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>  
                  
            
          </ValidatorForm>
          
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}


export default  (withStyles(useStyles) (Services) )

